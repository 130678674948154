import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Seo from "../../../components/seo"
import Layout from "../../../app/layout"

import { Row, Col, Breadcrumb, Pagination } from "antd"

import Container from "../../../components/Container"
import CardNews from "../../../components/CardNews"
import { ChevronRight } from "../../../components/Icon"
import Newsletter from "../../../components/Newsletter"
import FeaturedNews from "../../../components/FeaturedNews/FeaturedNews"
import ScrollAnimation from "react-animate-on-scroll"
import { useI18next } from "gatsby-plugin-react-i18next"

function NewsPage({ data }) {
  const news = data.news || {}

  const { highlighted_news = {}, item = [] } = news
  const { t } = useI18next()
  const [filter, setFilter] = useState({
    page: 1,
  })
  let items = [...item]
  if (items.length > 2) {
    items.splice(2, 0, { component: <Newsletter /> })
  } else {
    items = [...item, { component: <Newsletter /> }]
  }
  const paging = page => {
    setFilter(p => ({ ...p, page }))
  }
  return (
    <Layout module_name="information" page_name="news">
      <Seo title={t("Berita")} />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("Berita")}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium">
        <Container>
          {highlighted_news && Object.keys(highlighted_news).length > 0 && (
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <FeaturedNews
                image={highlighted_news.image}
                title={highlighted_news.title}
                date={highlighted_news.created_at}
                category={highlighted_news.news_category}
                excerpt={highlighted_news.content}
                url={highlighted_news.slug}
              />
            </ScrollAnimation>
          )}
          {items && items.length > 0 && (
            <Row gutter={30} style={{ marginTop: 24 }}>
              {items
                .slice((filter.page - 1) * 9, filter.page * 9)
                .map(function (item, i) {
                  if (item.component) {
                    return (
                      <Col span={24} md={8} key={i}>
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOnce={true}
                        >
                          <Newsletter />
                        </ScrollAnimation>
                      </Col>
                    )
                  }
                  return (
                    <Col span={24} md={8} key={i}>
                      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <CardNews
                          title={item.title}
                          category={item.news_category}
                          image={item.image}
                          date={item.created_at}
                          url={item.slug}
                        />
                      </ScrollAnimation>
                    </Col>
                  )
                  // }
                })}
            </Row>
          )}
          {items.length > 9 && (
            <Row justify="center" style={{ marginTop: 40, marginBottom: 40 }}>
              <Pagination
                current={Number(filter.page)}
                total={items.length}
                onChange={paging}
                defaultPageSize={9}
              />
            </Row>
          )}
        </Container>
      </div>
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  query NewsQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    news(lang: { eq: $language }) {
      highlighted_news {
        created_at
        content
        image
        slug
        title
        news_category
      }
      item {
        title
        created_at
        image
        slug
        news_category
      }
    }
  }
`
